import styled from "styled-components/macro";

export const Icon = styled.img`
  height: 90px;
  width: 90px;
  margin: 0 2em;
`;
const s3 = "https://ocv-web-icons.s3.us-east-2.amazonaws.com/";
export const WebIcons = (icon: string): any => {
  switch (icon) {
    case "alcohol":
      return <Icon src={s3 + "Alcohol-Addiction.png"} alt='alcohol' />;
    case "book":
      return <Icon src={s3 + "Book.png"} alt='book' />;
    case "calendar":
      return <Icon src={s3 + "Calendar-Events.png"} alt='calendar' />;
    case "caution":
      return <Icon src={s3 + "Caution.png"} alt='caution' />;
    case "checklist":
      return <Icon src={s3 + "Checklist-Prepare.png"} alt='checklist' />;
    case "comments":
      return <Icon src={s3 + "Comments-Concerns-Message.png"} alt='comments' />;
    case "crash":
      return <Icon src={s3 + "Crash.png"} alt='crash' />;
    case "directory":
      return <Icon src={s3 + "Directory-Contacts.png"} alt='directory' />;
    case "divisions":
      return <Icon src={s3 + "Divisions.png"} alt='divisions' />;
    case "dog":
      return <Icon src={s3 + "Dog.png"} alt='dog' />;
    case "drugs":
      return <Icon src={s3 + "Drugs-Pills-Addiction.png"} alt='drugs' />;
    case "facebook-square":
      return <Icon src={s3 + "facebook-square.svg"} alt='Facebook' />;
    case "faq":
      return <Icon src={s3 + "Faq-Questions.png"} alt='faq' />;
    case "fingerprint":
      return <Icon src={s3 + "Fingerprint.png"} alt='fingerprint' />;
    case "globe":
      return <Icon src={s3 + "Globe-SocialMedia.png"} alt='globe' />;
    case "gun":
      return <Icon src={s3 + "Gun.png"} alt='gun' />;
    case "menu":
      return <Icon src={s3 + "HamburgerMenu.png"} alt='menu' />;
    case "house":
      return <Icon src={s3 + "Home-House-Shelters.png"} alt='house' />;
    case "home":
      return <Icon src={s3 + "Home.png"} alt='home' />;
    case "info":
      return <Icon src={s3 + "Information-About.png"} alt='info' />;
    case "instagram-square":
      return <Icon src={s3 + "instagram-square.svg"} alt='Instagram' />;
    case "hancuffs":
      return <Icon src={s3 + "Inmates-Arrests.png"} alt='handcuffs' />;
    case "team":
      return (
        <Icon
          src={s3 + "JoinOurTeam-Community-Group-Employees.png"}
          alt='team'
        />
      );
    case "form":
      return <Icon src={s3 + "List-Form-Checklist.png"} alt='form' />;
    case "mail":
      return <Icon src={s3 + "Mail-Contacts.png"} alt='msil' />;
    case "map":
      return <Icon src={s3 + "Map-Pin-Location-1.png"} alt='map' />;
    case "mapPin":
      return <Icon src={s3 + "Map-Pin-Location.png"} alt='map pin' />;
    case "medical":
      return <Icon src={s3 + "Medical-Emergency.png"} alt='medical' />;
    case "money":
      return <Icon src={s3 + "Money-Donate.png"} alt='money' />;
    case "price":
      return <Icon src={s3 + "Money-SheriffSales.png"} alt='price' />;
    case "more":
      return <Icon src={s3 + "MORE.png"} alt='more' />;
    case "poster":
      return <Icon src={s3 + "MostWanted.png"} alt='poster' />;
    case "news":
      return <Icon src={s3 + "News-PressReleases.png"} alt='news' />;
    case "mobile":
      return <Icon src={s3 + "Phone-iPhone-1.png"} alt='globe' />;
    case "iphone":
      return <Icon src={s3 + "Phone-iPhone.png"} alt='iphone' />;
    case "programs":
      return <Icon src={s3 + "Programs-Catalog.png"} alt='programs' />;
    case "alerts":
      return <Icon src={s3 + "PushNotifications-Alerts.png"} alt='alerts' />;
    case "report":
      return <Icon src={s3 + "Reports.png"} alt='report' />;
    case "links":
      return <Icon src={s3 + "Resources-Links.png"} alt='links' />;
    case "school":
      return <Icon src={s3 + "School-Learn.png"} alt='school' />;
    case "search":
      return <Icon src={s3 + "Search-MangifyingGlass.png"} alt='search' />;
    case "personSearch":
      return <Icon src={s3 + "SexOffenders.png"} alt='school' />;
    case "handshake":
      return (
        <Icon
          src={s3 + "SheriffWelcome-Community-JoinOurTeam.png"}
          alt='handshake'
        />
      );
    case "submit":
      return <Icon src={s3 + "SubmitATip-Form-List-Report.png"} alt='submit' />;
    case "thief":
      return <Icon src={s3 + "Thief-Theft-Burglar-Crime.png"} alt='thief' />;
    case "thumbsUp":
      return <Icon src={s3 + "ThumbsUp-CommendADeputy.png"} alt='thumbs up' />;
    case "traffic":
      return <Icon src={s3 + "Traffic-RoadConditions.png"} alt='traffic' />;
    case "twitter-square":
      return <Icon src={s3 + "twitter-square.svg"} alt='Twitter' />;
    case "user":
      return <Icon src={s3 + "User-Profile-Employee.png"} alt='user' />;
    case "vine":
      return <Icon src={s3 + "VINE.png"} alt='vine' />;
    case "virus":
      return <Icon src={s3 + "Virus-Covid.png"} alt='virus' />;
    case "warrants":
      return <Icon src={s3 + "Warrants-Certificate.png"} alt='warrants' />;
    case "eye":
      return <Icon src={s3 + "Watch-Eye.png"} alt='eye' />;
    case "weather":
      return <Icon src={s3 + "Weather.png"} alt='weather' />;
    case "youtube-square":
      return <Icon src={s3 + "youtube-square.svg"} alt='YouTube' />;
    default:
      console.error("The icon you requested is not in our WebIcon S3 file.");
      return <span>The icon you requested is not in our WebIcon S3 file.</span>;
  }
};
