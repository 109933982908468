import { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

interface SectionProps {
  $bgColor: string;
  $bgPosition: string;
  $bgImage: string;
}

interface ButtonProps {
  $buttonColor: string;
  $buttonTextColor: string;
}

export const TwoColumnView = ({ manifestData, config }: any) => {
  const [twoColumnData, setTwoColumnData] = useState<any>();
  const url = manifestData?.["views"]["twoColumnView"]["url"];

  useEffect(() => {
    const fetchTwoColumnData = async () => {
      //This data is from a page in the control panel
      try {
        const response = await fetch(url);
        const data = await response.json();
        setTwoColumnData(data.data);
      } catch (e) {
        console.error(e);
      }
    };

    fetchTwoColumnData();
  }, [url]);

  return (
    <TwoColumnContainer $bgColor={config?.bgColor} $bgImage={config?.bgImage}>
      <Header
        dangerouslySetInnerHTML={{
          __html: twoColumnData?.title,
        }}
      />
      <FlexBox>
        <TextBox>
          <Text
            dangerouslySetInnerHTML={{
              __html: twoColumnData?.content,
            }}
          />
        </TextBox>
        <ImageBox>
          <Image src={twoColumnData?.images[0].large} />
          <Caption>{config?.caption}</Caption>
        </ImageBox>
      </FlexBox>
      {config?.external === true ? (
        <ExternalLink
          href={config?.url}
          target='_blank'
          rel='noopener'
          $buttonColor={config?.buttonColor}
          $buttonTextColor={config?.buttonTextColor}
        >
          {config.buttonText}
        </ExternalLink>
      ) : (
        <StyledLink
          to={config?.feature}
          $buttonColor={config?.buttonColor}
          $buttonTextColor={config?.buttonTextColor}
        >
          {config.buttonText}
        </StyledLink>
      )}
    </TwoColumnContainer>
  );
};

export const TwoColumnContainer = styled.section<SectionProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  flex-wrap: wrap;
  ${({ $bgColor }) => `background-color: ${$bgColor}`};
  ${({ $bgImage }) => `background-image: url(${$bgImage})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex-grow: 2;
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`;

export const TextBox = styled.div`
  width: 500px;
`;

export const Header = styled.h2`
  text-align: center;
  font-size: 2.25rem;
  font-family: Fjalla One, sans;
  text-transform: uppercase;
  padding-bottom: 1.5rem;

  @media screen and (max-width: 800px) {
    font-size: 1.5rem;
  }
`;

export const Text = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 1.1rem;
  margin: 1rem 0;

  @media screen and (max-width: 900px) {
    font-size: 0.8rem;
  }
`;
export const Caption = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 1.1rem;
  margin: 1rem 0;
  font-weight: bold;
  color: black;

  @media screen and (max-width: 900px) {
    font-size: 0.8rem;
  }
`;
export const ImageBox = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  position: relative;

  @media screen and (max-width: 1100px) {
    justify-content: center;
  }
`;

export const Image = styled.img`
  max-height: 25rem;
  position: relative;
`;
export const StyledLink = styled(Link)<ButtonProps>`
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1rem;
  height: 50px;
  min-width: 175px;
  color: ${({ $buttonTextColor }) => $buttonTextColor};
  background-color: ${({ $buttonColor }) => $buttonColor};
  border: 1px solid ${({ buttonTextColor }) => buttonTextColor};
  font-family: Fjalla One, sans-serif;
  padding: 12px 18px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;

  &:hover {
    color: ${({ $buttonTextColor }) => $buttonTextColor};
    text-decoration: none;
  }
`;

export const ExternalLink = styled.a<ButtonProps>`
  color: ${({ $buttonTextColor }) => $buttonTextColor};
  background-color: ${({ $buttonColor }) => $buttonColor};
  border: 1px solid ${({ buttonTextColor }) => buttonTextColor};
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1rem;
  height: 50px;
  min-width: 175px;
  font-family: Fjalla One, sans-serif;
  padding: 12px 18px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;

  &:hover {
    color: ${({ $buttonTextColor }) => $buttonTextColor};
    text-decoration: none;
  }
`;
